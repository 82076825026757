<template>
  <div ref="root" class="gwp_header_shrt_menu brder-left gwp_header_alram">
    <div class="gwp_button_wrap menu_box">
      <button
        :title="$t('Notification')"
        class="menu_box_img gwp_header_alram btn_slidedown"
        @click.prevent="toggleDropdown">
        <span class="menu_alram ico_header" :style="notificationIconStyle">
          <span v-show="notificationsCount > 0" class="noti_cnt">
            {{ notificationsCount > 99 ? '99+' : notificationsCount }}
          </span>
        </span>
        <span class="menu_txt">{{ $t('Notification') }}</span>
      </button>
    </div>
    <div class="gwp_box_slidedown_wrap">
      <slide-down>
        <div v-if="isDropdownVisible" class="box_slidedown gwp_alram_wrap" style="display: block" @click="onBoxClick">
          <div class="title_area">
            <span class="alram_title">{{ $t('NotificationCenter') }}</span>
            <button class="btn_alram_clear" @click.prevent="clearNotificationsAll">
              <span class="alram_all_clear" style="margin-right: 10px" :title="$t('NotificationClear')">{{
                $t('NotificationClearAll')
              }}</span>
            </button>
            <a href="javascript:void(0)" style="margin-right: 10px" @click="moveSetting">
              <button class="btn_alram_setting">
                <span class="ico_header ico_header_setting" :title="$t('NotificationSetting')"></span>
              </button>
            </a>
            <a href="javascript:void(0)" @click="moveMore">
              <button class="btn_alram_more">
                <span class="ico_header ico_header_more" :title="$t('NotificationMore')"></span>
              </button>
            </a>
          </div>
          <div class="alram_area">
            <div class="nano has-scrollbar">
              <div class="nano-content">
                <ul id="gwp_alram_list" class="gwp_alram_list" style="height: 100%; margin: 0">
                  <li v-for="message in messageItems?.messages" :key="message.id" class="gwp_alram_item">
                    <a
                      class="gwp_alram_anchor title"
                      href="javascript:void(0);"
                      style="width: calc(100% - 20px); margin-bottom: 5px"
                      @click.prevent="navigateToMessage(message)">
                      {{ message.messageTitle }}
                    </a>
                    <!-- eslint-disable-next-line vue/no-lone-template, vue/no-v-html -->
                    <span id="txtMessageMemo" class="alram_day memo" style="display: block; margin-top: 3px">
                      {{ decodeHtml(message.messageMemo ?? '') }}
                    </span>
                    <span class="alram_day time" style="margin-top: 3px">{{ message.currentFiredTime }}</span>
                    <button
                      class="btn_alram_clear"
                      style="display: block; top: 13px; bottom: inherit"
                      :title="$t('Delete')"
                      @click.prevent="clearNotifications(message.id)">
                      <span class="ico_header ico_header_alram_clear"></span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </slide-down>
    </div>
  </div>
</template>
<script setup lang="ts">
import SlideDown from '@gnb/components/animations/SlideDown.vue'
import { useManagerStore } from '@gnb/store/modules/notification-center/notifyManagerStore'
import { usePrivateObjectsStore } from '@gnb/store/modules/notification-center/notifyPrivateObjStore'
import { useNotifyGlobalSettingsStore } from '@gnb/store/modules/notification-center/notifyGlobalSettingsStore' //전역 스토어 개체 (쓰는곳이 많음)
import { GetItemResult } from 'mik-common/src/api-autogen/notificationCenter'
import { GetMessagePagingContainerModel } from 'mik-common/src/api-autogen/notificationCenter/models/get-message-paging-container-model'
import { installOptions } from '@gnb/modules/menus/main'
import { useUserInfoStore } from '@gnb/store/modules/user-info/user-info'
import { useRouter } from 'vue-router'

const root = ref(null)
const notificationsCount = ref(0) // 예시로 알림 개수를 0으로 설정
const messageItems = ref<GetMessagePagingContainerModel>()
const isDropdownVisible = ref(false)
const managerStore = useManagerStore()
const privateObjectsStore = usePrivateObjectsStore()
const notifyGlobalSettingsStore = useNotifyGlobalSettingsStore()
const manager = managerStore.manager

const router = useRouter()
const basePath = router.options.history.base == '' ? import.meta.env.V_NOTIFICATIONCENTER_PATH : ''

/** 알림 리스트 */
const instance = getCurrentInstance()
const i18n = useI18n()
// const langCode = ref(instance?.proxy?.$i18n.locale ?? 'ko');
const langCode = ref('ko')
/** host */
const host = ref('')

// 현재 URL이 '~/notification'인지 확인
//const isNotificationApp = ref(window.location.href.toLowerCase().includes('/notification'))

// WebSocket Connection 상태에 따른 스타일 처리
const isConnected = ref(true)
const userStore = useUserInfoStore()
const gnbStyle = userStore.gnbStyle
const headerStyleId = JSON.parse(gnbStyle).headerStyleId

const notificationIconStyle = computed(() => {
  if (headerStyleId == 'small_01') {
    if (isConnected.value) {
      return {
        'background-position-x': '-60px',
        'background-position-y': '-20px'
      }
    } else {
      return {
        'background-position-x': '-80px',
        'background-position-y': '-20px'
      }
    }
  } else {
    if (isConnected.value) {
      return {
        'background-position-x': '-90px',
        'background-position-y': '-40px'
      }
    } else {
      return {
        'background-position-x': '-115px',
        'background-position-y': '-40px'
      }
    }
  }
})

watch(
  () => privateObjectsStore.connection.acceptkey,
  (newVal, oldVal) => {
    if (newVal != oldVal) {
      //privateObjectsStore?.options?.eventHandlers?.received({})
      manager.api.getNotificationMessages(
        {
          // 옵션 객체: 필요한 경우 여기에 추가 매개변수를 설정합니다.
          observed: false,
          pageNum: 1,
          pageSize: 10
        },
        (data) => {
          // 성공 콜백: 데이터 처리 로직
          notificationsCount.value = data.data.unObserved
          messageItems.value = data.data
        },
        (error) => {
          // 에러 콜백: 에러 처리 로직
        },
        () => {
          // 완료 콜백: 호출이 완료된 후 실행할 로직
        }
      )
    }
  }
)

/** LifeCycleHook */
/** onMounted */
onMounted(() => {
  /** handle document click */
  document.addEventListener('click', handleDocumentClick)
  langCode.value = i18n.locale.value ?? 'ko'
  host.value = window.location.host

  if (!privateObjectsStore.connection.socket) {
    isConnected.value = false
  }

  //이벤트 함수 선언
  console.log('Set connectedHandler')
  notifyGlobalSettingsStore.setConnectedHandler(() => {
    // WebSocket이 연결되면 isConnected 값을 변경하여, notificationIconStyle을 변경함
    isConnected.value = true
    console.log('Call connectedHandler')
  })

  console.log('Set disconnectedHandler')
  notifyGlobalSettingsStore.setDisConnectedHandler(() => {
    // WebSocket 연결이 끊어지면 isConnected 값을 변경하여, notificationIconStyle을 변경함
    isConnected.value = false
    console.log('Call disconnectedHandler')
  })

  console.log('Set receivedHandler')
  notifyGlobalSettingsStore.setReceivedHandler((param: any) => {
    console.log('Call receivedHandler')
    manager.api.getNotificationMessages(
      {
        observed: false,
        page: 1,
        size: 10
      },
      (data) => {
        // 성공 콜백: 데이터 처리 로직
        notificationsCount.value = data.data.unObserved
        messageItems.value = data.data
      },
      (error) => {
        // 에러 콜백: 에러 처리 로직
        console.log('errorCallback - ' + error)
      },
      () => {
        // 완료 콜백: 호출이 완료된 후 실행할 로직
        console.log('finishCallback')
      }
    )
  })

  console.log('Set pulledHandler')
  notifyGlobalSettingsStore.setPulledHandler((param: any) => {
    console.log('Call pulledHandler')
    manager.api.getNotificationMessages(
      {
        observed: false,
        page: 1,
        size: 10
      },
      (data) => {
        // 성공 콜백: 데이터 처리 로직
        notificationsCount.value = data.data.unObserved
        messageItems.value = data.data
      },
      (error) => {
        // 에러 콜백: 에러 처리 로직
        console.log('errorCallback - ' + error)
      },
      () => {
        // 완료 콜백: 호출이 완료된 후 실행할 로직
        console.log('finishCallback')
      }
    )
  })

  //이벤트 설정후 서버 접근
  manager.api.connectNotificationServer()
})
onBeforeUnmount(() => {
  document.removeEventListener('click', handleDocumentClick)
})

const toggleDropdown = () => {
  isDropdownVisible.value = !isDropdownVisible.value
}

const onBoxClick = (event: MouseEvent) => {
  // 여기서 event는 MouseEvent 타입으로 처리됩니다.
  if (event.target) {
    const target = event.target as HTMLElement // event.target을 HTMLElement로 형변환
    if (!['button', 'a'].includes(target.tagName.toLowerCase())) {
      event.stopPropagation()
    }
  }
}

/** 외부 클릭 시 DropDown 닫힘 처리 */
const handleDocumentClick = (e: Event) => {
  if (e == undefined || e == null) {
    return
  }
  // @ts-ignore
  if (!root.value.contains(e.target)) {
    isDropdownVisible.value = false
  }
}

// 알림 메시지를 지우는 함수를 구현해야 합니다.
const clearNotifications = async (id: number | undefined) => {
  //알림메시지 clearapi호출 후 알림메시지 갱신
  await manager.api.clearNotificationActivated({
    index: id
  })
  // 알림이 삭제되면 알림목록을 다시 가져오고 알림 카운트를 갱신합니다.
  await manager.api.getNotificationMessages(
    {
      observed: false,
      pageNum: 1,
      pageSize: 10
    },
    (data) => {
      // 성공 콜백: 데이터 처리 로직
      messageItems.value = data.data
      notificationsCount.value = data.data.unObserved
    },
    (error) => {
      // 에러 콜백: 에러 처리 로직
    },
    () => {
      // 완료 콜백: 호출이 완료된 후 실행할 로직
    }
  )
}

//모두지우기 이벤트
const clearNotificationsAll = () => {
  //ui부터 숨기기
  isDropdownVisible.value = false
  //알림카운트 즉시 지우기
  notificationsCount.value = 0
  messageItems.value = undefined
  // 알림 메시지 지우는 로직을 구현
  manager.api.clearNotificationActivated({
    index: 0
  })
}

const navigateToMessage = async (message: GetItemResult) => {
  await manager.api.moveToMessageUrl(message)
  manager.api.getNotificationMessages(
    {
      observed: false,
      pageNum: 1,
      pageSize: 10
    },
    (data) => {
      // 성공 콜백: 데이터 처리 로직
      messageItems.value = data.data
      notificationsCount.value = data.data.unObserved
    },
    (error) => {
      // 에러 콜백: 에러 처리 로직
    },
    () => {
      // 완료 콜백: 호출이 완료된 후 실행할 로직
    }
  )
  isDropdownVisible.value = false
}

/** 알림설정 full link */
const computedLink = computed(() => {
  const host = installOptions.baseUrl ?? ''
  console.log('computedLink', host)
  return `${host}/Notification/Configuration/Personal`
})

/** 개인 알림 목록 full link */
const computedLinkMessage = computed(() => {
  const host = installOptions.baseUrl ?? ''
  console.log('computedLink', host)
  return `${host}/Notification/Message/Items`
})

/** Html Decode */
const decodeHtml = (strHtml: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = strHtml
  return txt.value
}

const moveSetting = () => {
  const host = installOptions.baseUrl ?? ''
  if (host) {
    location.href = `${host}${basePath}/Configuration/Personal`
  } else {
    router.push(`${host}${basePath}/Configuration/Personal`)
  }
}

const moveMore = () => {
  const host = installOptions.baseUrl ?? ''
  if (host) {
    location.href = `${host}${basePath}/Message/Items`
  } else {
    router.push(`${host}${basePath}/Message/Items`)
  }
}
</script>

<style scoped>
.noti_cnt {
  font-size: 11px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  position: absolute;
  left: -9px;
  top: 1px;
  display: inline-block;
  box-sizing: border-box;
  min-width: 18px;
  height: 18px;
  padding: 0 5px;
  border-radius: 10px;
  background: #f98268;
}

/* .gwp_alram_item {
  margin: 0px 20px 0px 20px;
} */

.menu_alram.ico_header {
  /* background: url(/PortalResources/images/alpha/common/left-icon_SP_S1000000.png) no-repeat; */
}

/* 화살표 아이콘 */
.gnb_theme_type_small_01 div.title_area::before {
  right: 132px !important;
}
.gnb_theme_type_medium_01 div.title_area::before {
  right: 148px !important;
}

/* dropdown 위치 조절 */
#lay_header .gwp_alram_wrap {
  left: -100px;
  height: 320px;
}

/* app 아이템 */
.alram_item {
  width: 25%;
}

.alram_list {
  width: 292px;
}

.nano-pane {
  right: 0 !important;
}

.main .q-scrollarea__content {
  max-width: 100% !important;
}
</style>
