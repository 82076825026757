<template>
  <div ref="root" class="gwp_header_shrt_menu brder-left gwp_header_fav gwp_header_myapp">
    <div class="gwp_button_wrap menu_box">
      <button
        :title="$t('AppMenu')"
        class="menu_box_img gwp_btn_header_fav btn_slidedown"
        @click.prevent="handleGnbMyAppClick">
        <span class="myapp_all ico_header"></span>
        <span class="menu_txt">{{ $t('AppMenu') }}</span>
      </button>
    </div>

    <div class="gwp_box_slidedown_wrap">
      <slide-down>
        <div v-if="isMyAppVisible" class="gwp_fav_wrap box_slidedown gwp_app_wrap" style="display: block">
          <div class="title_area">
            <span class="fav_title">My App</span>
            <button class="btn_fav_setting" @click.prevent="handleMyAppSettingClick">
              <span class="ico_header ico_header_setting"></span>
            </button>
          </div>
          <div class="app_area">
            <!-- <perfect-scrollbar> -->
            <div class="nano has-scrollbar">
              <div class="nano-content" tabindex="0">
                <ul id="gnb_app_list" class="app_list">
                  <li v-for="item in myAppList" :key="item.appId ?? ''" class="app_item">
                    <a
                      href="javascript:void(0)"
                      class="app_anchor"
                      @click.prevent="handleMyAppClick($event, item.actionType ?? '', item.actionValue ?? '')">
                      <span class="app_icon">
                        <span class="app_icon_wrap">
                          <img
                            :src="handleImgSrc(item.appId ?? '')"
                            :style="styleByType(item.actionType ?? '')"
                            alt="" />
                        </span>
                      </span>
                      <span class="app_name">{{ itemName(item.appNameJson ?? '') }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div class="nano-pane" style="display: block; opacity: 1; visibility: visible;"><div class="nano-slider" style="transform: translate(0px, 0px); height: 20px;"></div></div></div> -->
            <!-- </perfect-scrollbar> -->
          </div>
        </div>
      </slide-down>
    </div>
  </div>
  <!--
  <mik-layer-popup ref="MikCommonPopup">
    <template #mikLayerPopupBody>
      <my-app-setting :my-app-list="myAppList"
                      @on-hide-popup="handleMyAppSettingClose"> </my-app-setting>
    </template>
  </mik-layer-popup> -->

  <!-- myAppSetting pop-->
  <div>
    <!-- <common-popup
      :model-value="isMyAppSettingVisible"
      :title="$t('MyAppSettings')"
      size="m"
      :on-cancel="handleMyAppSettingClose">
      <div>
        <my-app-setting
          :my-app-list="myAppList"
          :img-src-list="imgSrcList"
          @on-hide-popup="handleMyAppSettingClose"></my-app-setting>
      </div>
    </common-popup> -->
    <mik-common-popup
      v-model="isMyAppSettingVisible"
      :title="$t('MyAppSettings')"
      size="m"
      :on-cancel="handleMyAppSettingClose">
      <template #body>
        <my-app-setting :my-app-list="myAppList" :img-src-list="imgSrcList" @on-hide-popup="handleMyAppSettingClose">
        </my-app-setting>
      </template>
    </mik-common-popup>
  </div>
</template>

<script setup lang="ts">
import SlideDown from '@gnb/components/animations/SlideDown.vue'
import { MyAppModel } from 'mik-common/src/api-autogen/globalmenu'
import MyAppSetting from './MyAppSetting.vue'
import MikCommonPopup from '../common/MikCommonPopup.vue'
//import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { MyAppApi } from 'mik-common/src/api-autogen/globalmenu'
const api = new MyAppApi()
import useGwpGlobalMenu, { navigateToUrl } from '@gnb/utils/gnb'

const root = ref(null)
/** 마이앱 DropDown Visible */
const isMyAppVisible = ref(false)
/** 마이앱 데이터 */
const myAppList = ref<MyAppModel[]>([])
/** Mik Layer Popup */
//const MikCommonPopup = ref<InstanceType<typeof mikLayerPopup>>()
/** LangCode */
const instance = getCurrentInstance()
// const langCode = ref(instance?.proxy?.$i18n.locale ?? 'ko');
const langCode = ref('ko')
/** host */
const host = ref('')
// get gwpGlobalMenu Instance
const { openYellowPage, gwpOpenCenter } = useGwpGlobalMenu()
/* 마이앱 설정 popup*/
const isMyAppSettingVisible = ref(false)

const i18n = useI18n()

/** LifeCycleHook */
/** onMounted */
onMounted(() => {
  /** handle document click */
  document.addEventListener('click', handleDocumentClick)
  langCode.value = i18n.locale.value ?? 'ko'
  host.value = window.location.host
})
onBeforeUnmount(() => {
  document.removeEventListener('click', handleDocumentClick)
})

/** get myApp */
const getMyAppList = () => {
  api.v1MyappGet().then((response) => {
    if (response.data.success && response.data.data) {
      myAppList.value = response.data.data
      imgSrcList.value.slice(0)
      handleGetImgSrc() //get icon
    }
  })
}
/** app name */
const itemName = (json: string): string => {
  // langCode reactive로 해야됨
  // let langCode = i18n.locale;
  const jsonName = JSON.parse(json) ?? {}
  return jsonName[langCode.value]
}

/** get app img interface */
// interface imgSrc {
//   appId: string
//   imgSrc: string
// }
const imgSrcList = ref<MyAppModel[]>([])
/** get app image base64 data */
const handleGetImgSrc = (): string => {
  api.v1MyappImagesPost().then((response) => {
    if (response.data.success && response.data.data) {
      // const img:imgSrc = {"appId" : appId , "imgSrc" :}
      imgSrcList.value = response.data.data
      console.log('imgSrcList', imgSrcList.value[0])
    } else {
      console.log('v1MyappImagesAppIdPost err', response.data.message)
      return ''
    }
  })
  return ''
}
/** mapping img src */
const prefix = ref('app_')
const handleImgSrc = (appId: string) => {
  const imgSrcTemp = ref<MyAppModel[]>([])
  imgSrcTemp.value = imgSrcList.value.filter(function (item) {
    return item.appId?.toLocaleLowerCase() === prefix.value + appId.toLocaleLowerCase()
  })
  return imgSrcTemp.value.length > 0 ? (imgSrcTemp.value[0].imgSrc ?? '') : ''
}
/** app img style */
const styleByType = (type: string) => {
  const style = type.toLocaleLowerCase() == 's' ? { width: '32px', height: '32px' } : {}
  return style
}
/** 외부 클릭 시 DropDown 닫힘 처리 */
const handleDocumentClick = (e: Event) => {
  if (e == undefined || e == null) {
    return
  }
  /**
   * 해당 컴포넌트 외부에서 클릭이 되었을 경우, drop down을 닫기위한 기능 연결
   */
  // @ts-ignore
  if (!root.value.contains(e.target)) {
    isMyAppVisible.value = false
  }
}
/** Gnb 마이앱 클릭 */
const handleGnbMyAppClick = () => {
  if (!isMyAppVisible.value) getMyAppList()
  isMyAppVisible.value = !isMyAppVisible.value
}
/** 마이앱 설정 클릭 */
const isViewPopup = ref(false)
const myAppTitleText = ref('')
const commonPopupClose = () => {
  isViewPopup.value = false
}

const handleMyAppSettingClick = () => {
  isMyAppVisible.value = false
  isMyAppSettingVisible.value = true
  isViewPopup.value = !isViewPopup.value

  const i = document.body
  i.classList.add('body-prevent-scroll')
}

/** 마이앱 클릭 */
const handleMyAppClick = (event: MouseEvent, type: string, value: string) => {
  // s 인 경우 javascript DB Url '' 변경 필요
  if (type.toLocaleLowerCase() == 's') {
    openYellowPage('')
  } else {
    if (event.ctrlKey) {
      handleOpenTab(value)
    } else {
      navigateToUrl(value)
    }
  }
}
/** close setting popup */
const handleMyAppSettingClose = () => {
  const i = document.body
  i.classList.remove('body-prevent-scroll')

  isMyAppSettingVisible.value = false
}
/** tab open url */
const handleOpenTab = (url: string) => {
  window.open(url, '_blank', '')
}
</script>

<style scoped>
.myapp_all.ico_header {
  /* background: url(/PortalResources/images/alpha/common/left-icon_SP_S1000000.png) no-repeat; */
}

/* 화살표 아이콘 */
div.title_area::before {
  right: 148px !important;
}

/* dropdown 위치 조절 */
#lay_header .gwp_app_wrap {
  left: -170px;
  height: 360px;
}

.app_area {
  margin: 0 20px;
  padding: 20px 0;
  /* height: calc(100% - 60px); */
  height: 260px;
}

/* app 아이템 */
.app_item {
  width: 25%;
}

.app_list {
  width: 292px;
}

.nano-pane {
  right: 0 !important;
}

#q-portal--dialog--1 {
  margin-left: 100px;
}

.app_name {
  font-size: 12px;
  /* 20240105 css v1을 덮어쓰기 하니 폰트 사이즈가 너무 커서 추가 */
}
</style>
