<template>
  <!-- ToDo.
        1) Addin 영역은 일단 주석처리 추후 필요 시 추가 및 구현
        2) Folder 표시 구현
    -->
  <div id="lay_lnb" class="gwp_default">
    <div class="lnb_menu">
      <div class="lnb_header">
        <div class="lnb_title" @click.prevent="handleTitleClick">{{ useLocalMenu.locDefaultTitle }}</div>
      </div>
      <!-- <div id="div_lnb_topaddin_a" style="display: none;"></div>
            <div id="div_lnb_topaddin_b" style="display: none;"></div>
            <div id="div_lnb_topaddin_c" style="display: none;"></div> -->

      <!-- LNB 루트 추가 -->
      <template v-if="useLocalMenu.locItems.length > 0">
        <local-menu-list
          :items="useLocalMenu.locItems"
          :expanded-items="useLocalMenu.locExpandedItems"
          :is-all-expanded="useLocalMenu.locAllExpanded"
          @on-menu-click="handleMenuClick"
          @on-menu-expand="handleMenuExpand" />
      </template>
      <template v-else>
        <local-menu-item :item="{ id: useLocalMenu.locDefaultMenuId }" :depth="1" :show-title="false"></local-menu-item>
      </template>

      <!-- <div id="div_lnb_bottomaddin_a" style="display: none;"></div>
            <div id="div_lnb_bottomaddin_b" style="display: none;"></div>
            <div id="div_lnb_bottomaddin_c" style="display: none;"></div>
            <div id="div_lnb_bottomaddin_d" style="display: none;"></div>
            <div id="div_lnb_bottomaddin_e" style="display: none;"></div> -->

      <!-- eslint-disable-next-line vue/no-v-html -->
      <div id="SP_lnb_bottom_desc"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import LocalMenuItem from './local-items/LocalMenuItem.vue'
import LocalMenuList from './local-items/LocalMenuList.vue'

import { useGlobalMenuStore } from '@gnb/store/modules/global-menu/global-menu'

import { LocalMenuModel } from 'mik-common/src/api-autogen/globalmenu'

import useGwpGlobalMenu from '@gnb/utils/gnb'

interface Props {
  // isAllExpanded?: boolean
  bannerHtml?: string
  // title?: string
  // items?: Object[]
  // selectedItem?: Object
  url?: string
  langCode?: string
  showHiddenMenu?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  bannerHtml: '',
  url: '',
  langCode: '',
  showHiddenMenu: false
})

const { globalMenuClick } = useGwpGlobalMenu()
//const expandedItems: LocalMenuModel[] = reactive([])
const useLocalMenu = useGlobalMenuStore()
//const useUserStore = useUserInfoStore()

onMounted(async (): Promise<void> => {
  let url = props.url
  if (url == '') {
    url = location.href
  }
  await getLocalMenu(url)
})

const getLocalMenu = async (url: string) => {
  await useLocalMenu.fetchItems(undefined, true, url, 'localMenu')
}

watch(
  () => props.url,
  async (newValue, oldValue) => {
    await getLocalMenu(newValue)
  }
)

const updateExpandedItems = (item: LocalMenuModel, isExpanded: boolean): void => {
  const hasItem = useLocalMenu.locExpandedItems.some((expandedItem: LocalMenuModel) => expandedItem.id === item.id)
  //console.log(`hasItem: ${hasItem}`);

  if (isExpanded) {
    if (!hasItem) {
      useLocalMenu.locExpandedItems.push(item)
    }
  } else {
    if (hasItem) {
      const index = useLocalMenu.locExpandedItems.findIndex((x: LocalMenuModel) => x.id == item.id)
      if (index > -1) {
        useLocalMenu.locExpandedItems.splice(index, 1)
      }
      // Object.assign(expandedItems, expandedItems.filter(
      //   (expandedItem: LocalMenuModel) => expandedItem.id !== item.id
      // ));
    }
  }
  //console.log(`expandedItems: ${JSON.stringify(expandedItems)}`);
}

//현재 메뉴 활성화 상태 확인
//자식 메뉴가 선택되어 있으면 부모 메뉴도 활성화 상태로 표시되어야 함.
const isActiveItem = (item: LocalMenuModel, selecteditem: LocalMenuModel): boolean => {
  if (!selecteditem) {
    return false
  }

  if (item.id === selecteditem.id) {
    return true
  }

  let isActive: boolean = false
  const length: number = item.children?.length ?? 0

  for (let i = 0; i < length; i++) {
    if (item.children) isActive = isActiveItem(item.children[i], selecteditem)
    if (isActive) break
  }

  return isActive
}

const handleTitleClick = () => {
  emit('on-title-click')
}

const handleMenuClick = async (selectedItem: LocalMenuModel) => {
  //context.emit("on-menu-click", selectedItem);
  useLocalMenu.locClearSelectedItem()
  //console.log('selectedItem.name', selectedItem.name)
  //useLocalMenu.locUpdateLocalMenuTitle(selectedItem.name)
  useLocalMenu.locUpdateSelectedItem(selectedItem)
  await globalMenuClick(selectedItem.url, selectedItem.id)
  //console.log(`handleMenuClick: ${selectedItem.id} ${selectedItem.url}`);
}

const handleMenuExpand = (item: LocalMenuModel, isExpanded: boolean): void => {
  updateExpandedItems(item, isExpanded)
  //context.emit("on-menu-expand", item, isExpanded);
}

const emit = defineEmits<{ (e: 'on-title-click'): void }>()
</script>
<style>
/* Gnb css */
@import url('@gnb/../public/css/lnb.css');
</style>
